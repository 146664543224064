import { createSlice } from '@reduxjs/toolkit'

export const checkedSlice = createSlice({
    name: 'checked',
    initialState: {
        value: ['pos', 'ref', 'alt', 'rsID', 'gnomAD_AF', 'gnomAD_NFE_AF', 'freq_all', 'Gene', 'Consequence', 'filter']
    },
    reducers: {
        checkedAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { checkedAction } = checkedSlice.actions

export default checkedSlice.reducer