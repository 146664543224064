import { createSlice } from '@reduxjs/toolkit'

export const notFoundSlice = createSlice({
    name: 'notFound',
    initialState: {
        value: []
    },
    reducers: {
        notFoundAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { notFoundAction } = notFoundSlice.actions

export default notFoundSlice.reducer
