import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function Graphs() {

    const resvar = useSelector((state) => state.resvar.value)
    const resfull = useSelector((state) => state.resfull.value)
    const { t, i18n } = useTranslation()

    // if (resvar === {}) {
    //     return (<> </>)
    // }
    // Sex frequency
    const options_sex = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: [t('female'), t('male')],
        colors: ['#E91E63', '#4287f5'],
        title: {
            text: t('sex'),
            align: 'center'
        },
        legend: {
            floating: true
        }
    }

    //const series_sex = [Number(resvar.sex_F_samples_freq.toFixed(3)), Number(resvar.sex_M_samples_freq.toFixed(3))]
    const F_total = resvar.sex_F_samples
    const M_total = resvar.sex_M_samples
    const F_adj = resvar.sex_F_count / F_total
    const M_adj = resvar.sex_M_count / M_total
    const F_norm = F_adj / (F_adj + M_adj)
    const M_norm = M_adj / (F_adj + M_adj)
    const series_sex = [Number(F_norm.toFixed(3)), Number(M_norm.toFixed(3))]

    // Allelic frequency 
    var series_freq = resfull.map(x => x.freq_all)
    var legend = resfull.map(x => x.alt)
    var labels = resfull.map(x => x.alt)
    if (series_freq.reduce((partialSum, a) => partialSum + a, 0) != 1) {
        series_freq.push(1 - series_freq.reduce((partialSum, a) => partialSum + a, 0))
        var ref = resvar.ref.length > 3 ? resvar.ref.slice(0, 3) + '... (Ref)' : resvar.ref + ' (Ref)'
        legend = legend.map(x => x.length > 3 ? x.slice(0, 3) + '...' : x)
        legend.push(ref)
        labels.push(resvar.ref + ' (Ref)')
    }
    var idx = new Array(series_freq.length)
    for (let i = 0; i < series_freq.length; ++i) idx[i] = i
    idx.sort(function (a, b) { return series_freq[a] < series_freq[b] ? -1 : series_freq[a] > series_freq[b] ? 1 : 0 }).reverse()

    legend = idx.map(i => legend[i])
    series_freq = idx.map(i => series_freq[i])
    labels = idx.map(i => labels[i])
    legend = legend.slice(0, 3)




    const options_freq = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: labels,
        title: {
            text: t('frequency'),
            align: 'center'
        },
        legend: {
            floating: true,
            customLegendItems: legend
        }

    }


    // const options_alt = {
    //     chart: {
    //         type: 'pie'
    //     },
    //     labels: Object.keys(resvar.alt),
    //     title: {
    //         text: t('alt'),
    //         align: 'center'
    //     },
    //     legend: {
    //         floating: false,
    //         position: 'bottom'
    //     }

    // }
    // const series_alt = Object.values(resvar.alt)


    return (
        <div>
            <Row>
                <ReactApexChart options={options_sex} series={series_sex} type="pie" width={380} />
                <ReactApexChart options={options_freq} series={series_freq} type="pie" width={380} />

            </Row>
        </div>
    )
    // <ReactApexChart options={options_alt} series={series_alt} type="pie" width={330} height={880}/>
}

export default Graphs