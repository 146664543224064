import { cloneDeep } from "lodash"
import { useEffect, useState } from "react"
import { Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import AnimatedPage from "./AnimatedPage"
import SearchBar from "./SearchBar"
import SearchTable from "./SearchTable"
import { loadingAction } from "./Slices/loadingSlice"
import { restableAction } from "./Slices/restableSlice"
import { resvarAction } from "./Slices/resvarSlice"
import { CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import { notFoundAction } from "./Slices/notFoundSlice"
import { limitAction } from "./Slices/limitSlice"
import { errorAction } from "./Slices/errorSlice"



const SearchInfo = ({ icon, children }) => {
    return (
        <Paper sx={{ mt: 5, mb: 5 }} elevation={0}>
            <Grid container spacing={2} alignItems="center">
                <Grid item md={4} xs={2} lg={2}>
                    {icon}
                </Grid>
                <Grid item md={8} xs={10} lg={10}>
                    <Typography variant="h6">{children}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
const Search = () => {
    // const [string, setString] = useState(null)
    const navigate = useNavigate()
    const restable = useSelector((state) => state.restable.value)
    const loading = useSelector((state) => state.loading.value)
    const notFound = useSelector((state) => state.notFound.value)
    const limit = useSelector((state) => state.limit.value)
    const error = useSelector((state) => state.error.value)
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const [trigger, setTrigger] = useState(0)
    var [openAlert, setOpenAlert] = useState(false)
    // const [query, setQuery] = useState('')
    // It is a hook imported from 'react-i18next'

    // function handleQuery(x) {
    //   setString(x)
    // }


    function changeVariantRoute(variant) {
        navigate('/variant/' + variant)
    }

    const clearRestable = () => {
        document.querySelector("#SearchQuery").value = ''
        setTrigger(Math.random(1))
        dispatch(restableAction(null))
        dispatch(notFoundAction(null))
        dispatch(limitAction(null))
        dispatch(errorAction(null))
    }

    var dispTable = cloneDeep(restable)
    // if (dispTable?.length > 0) {
    //     if (typeof (dispTable) == 'object') {
    //         dispTable.forEach(function (x) {
    //             x.alt = JSON.stringify(x.alt)
    //             x.annot = JSON.stringify(x.annot)
    //             x.region = JSON.stringify(x.region)
    //             x.sex = JSON.stringify(x.sex)
    //         })
    //     }
    // }

    // function singleVariant() {
    //     changeVariantRoute(restable[0].pos)
    //     dispatch(resvarAction(restable[0]))
    //     dispatch(restableAction([]))
    // }

    useEffect(() => {
        dispatch(loadingAction(false))
    }, [restable])

    // function getQuery(query) {
    //     setQuery(query)
    // }

    /*!query.includes('-') & dispTable?.length == 1 ? // Do we have a sigle result?
                    singleVariant() : */

    var displayedResult = null
    if (window.location.pathname == '/') {
        if (!(typeof (dispTable) == 'object')) {
            if (dispTable?.length > 0 ? dispTable.startsWith('error') : 0) {
                displayedResult = <h1>{t('error')}</h1>
            } else if (dispTable?.length > 0 ? dispTable.startsWith('Query error') : 0) {
                displayedResult = <h1>{dispTable}</h1>
            } else if (dispTable?.length > 0 ? dispTable.startsWith('Database error') : 0) {
                displayedResult = <h1>{dispTable}</h1>
            } else if (dispTable?.length > 0 ? dispTable.startsWith('Server Error') : 0) {
                displayedResult = 'Server Error: Request failed with status code 403' == dispTable ? 'Logged out due to inactivity, please refresh the page' : <h1>{dispTable}</h1>
            } else if (dispTable?.length > 0 ? dispTable.startsWith('Query Error') : 0) {
                displayedResult = <h1>{dispTable}</h1>
            }
        } else if (loading) {
            displayedResult = <div>
                <SearchInfo icon={<CircularProgress />}>
                    {t('variant_search')}
                </SearchInfo>
            </div>
        } else if (dispTable?.length == 0) {
            displayedResult = <h1>{t('empty')}</h1>
        } else if (dispTable?.length > 0) {
            displayedResult = <AnimatedPage>
                <SearchTable data={dispTable} changeRoute={changeVariantRoute} />
            </AnimatedPage>
        } else {
            displayedResult = <div><SearchInfo icon={<SearchIcon sx={{ fontSize: "5em" }} />}>
                {t('guide')}
                <br />
                <br />
                <Row style={{ whiteSpace: 'break-spaces', display: 'contents' }}>
                    <b>{t('query_format')}</b>
                    {`
• ` + t('single_pos') + `: chr:position - chr1:10002030
• `+ t("single_var") + `: chr:position>alt - chr1:10002030>A ` + t("or") + ` chr1:10002030A
• `+ t("reg_search") + `: chr:start-stop - chr1:10000000-10050000
• `+ t("gene_search") + `INS`}
                </Row>
                <br />
                <br />
                <Row style={{ whiteSpace: 'break-spaces', display: 'contents' }}>
                    {t('multi_query_inf')}
                </Row>
            </SearchInfo>
                <br />
                <hr style={{ width: "80%" }} />
                <Row style={{ marginLeft: "200px" }}>
                    {t('acknowledgements1')}
                    <br />
                    {t('acknowledgements2')}
                    <br />
                    {t('acknowledgements3')}
                </Row>
            </div >
        }
    }


    return (
        <>
            <Row style={{ placeContent: 'center' }}>
                <SearchBar trigger={trigger}></SearchBar> {/* getQuery={getQuery}  */}
                <Button variant="outlined" style={{ display: dispTable?.length == null ? 'none' : 'block' }} onClick={clearRestable}>{t('clear')}</Button>
            </Row>
            <Divider sx={{ mt: "1em", mb: "1em", width: "75%", ml: "auto", mr: "auto" }}></Divider>
            <Collapse in={notFound?.length > 0}>
                <Alert
                    severity="warning"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(notFoundAction([]))
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    <AlertTitle>{t("not_found")}</AlertTitle>
                    {"'" + notFound?.filter(x => x.trim().length != 0).join("', '") + "'"}
                </Alert>
            </Collapse>
            <Collapse in={limit?.length > 0}>
                <Alert
                    severity="warning"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(limitAction([]))
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    <AlertTitle>{t("query_limit")}</AlertTitle>
                    {"'" + limit?.filter(x => x.trim().length != 0).join("', '") + "'"}
                </Alert>
            </Collapse>
            <Collapse in={error?.length > 0}>
                <Alert
                    severity="warning"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(errorAction([]))
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    <AlertTitle>{t("query_error")}</AlertTitle>
                    {"'" + error?.map(x => x.sample + ': ' + x.msg).join("', '") + "'"}
                </Alert>
            </Collapse>
            <Row style={{ placeContent: 'center' }}>
                {displayedResult}
            </Row>
        </>
    )
}

export default Search;