import { createRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Graphs from './Graphs'
import { Container, Row, Button, Col } from 'react-bootstrap'
import { Tooltip } from "react-svg-tooltip"
import { useTranslation } from "react-i18next"
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { resvarAction } from './Slices/resvarSlice'
import { resfullAction } from './Slices/resfullSlice'
import AnimatedPage from './AnimatedPage'
import { cloneDeep } from "lodash"
import config from '../Config'
import { useAuth } from 'react-oidc-context'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Divider } from "@mui/material"

import Color from 'color';


function Variant() {
    const { t } = useTranslation()
    const resvar = useSelector((state) => state.resvar.value)
    const resfull = useSelector((state) => state.resfull.value)
    const auth = useAuth();
    const api = axios.create({ baseURL: config.REACT_APP_BASE_API_URL, headers: { Authorization: `Bearer ${auth.user?.id_token}` } })
    //const api = axios.create({ baseURL: 'http://' + window.location.hostname + ':5000' })
    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const [showAnnot, setShowAnnot] = useState(null)
    const [showReg, setShowReg] = useState(null)
    const [map, setMap] = useState(null)


    function handleOpen() {
        setShowAnnot(true)
    }
    function handleClose() {
        setShowAnnot(false)
    }
    function handleOpenReg() {
        setShowReg(true)
    }
    function handleCloseReg() {
        setShowReg(false)
    }

    useEffect(() => {
        const fetchMap = async () => {
            try {
                // Dynamically import the gene_list module
                const MapModule = await import('./Mapa');

                // Access the default export from the module
                const paths = MapModule.default;

                // Update the state with the fetched gene list
                setMap(paths);
            } catch (error) {
                console.error('Error fetching gene list:', error);
            }
        }
        fetchMap()
    })


    //if resvar empty (so we just enter the query in the url)
    if (resvar.length < 1) {
        api.get('/varList', { params: { string: decodeURI(window.location.pathname).replace("/variant/", "") } })//location.pathname.replace("/variant/", "")
            .then(res => {
                if (res.data.res == 'error') {
                    setText(t('error'))
                } else if (res.data.res  == '[]') {
                    setText('empty')
                } else {
                    setText(null)
                    dispatch(resvarAction(res.data.res[0][0]))
                }

            })
        //
        if (new RegExp('[>]').test(decodeURI(window.location.pathname).replace("/variant/", ""))) {
            api.get('/varList', { params: { string: decodeURI(window.location.pathname).split('>')[0].replace("/variant/", "") } })//location.pathname.replace("/variant/", "")
                .then(res => {
                    dispatch(resfullAction(res.data.res[0]))
                })
        } else {
            api.get('/varList', { params: { string: decodeURI(window.location.pathname).split('>')[0].replace("/variant/", "").match(/^(.*)[0-9]/, '')[0] } })//location.pathname.replace("/variant/", "")
                .then(res => {
                    dispatch(resfullAction(res.data.res[0]))
                })
        }

    }

    const PrahaRef = createRef()
    const JihočeskýRef = createRef()
    const JihomoravskýRef = createRef()
    const KarlovarskýRef = createRef()
    const KrálovehradeckýRef = createRef()
    const LibereckýRef = createRef()
    const MoravskoslezskýRef = createRef()
    const OlomouckýRef = createRef()
    const PardubickýRef = createRef()
    const PlzeňskýRef = createRef()
    const StředočeskýRef = createRef()
    const ÚsteckýRef = createRef()
    const VysočinaRef = createRef()
    const ZlínskýRef = createRef()

    // if (resvar == undefined | resvar.length < 1) {
    //     return null
    // }

    const krajRefList = [PrahaRef, JihočeskýRef, JihomoravskýRef, KarlovarskýRef, KrálovehradeckýRef, LibereckýRef, MoravskoslezskýRef, OlomouckýRef, PardubickýRef, PlzeňskýRef, StředočeskýRef, ÚsteckýRef, VysočinaRef, ZlínskýRef]
    const krajRefObject = { "PHA": PrahaRef, "JHC": JihočeskýRef, "JHM": JihomoravskýRef, "KVK": KarlovarskýRef, "HKK": KrálovehradeckýRef, "LBK": LibereckýRef, "MSK": MoravskoslezskýRef, "OLK": OlomouckýRef, "PAK": PardubickýRef, "PLK": PlzeňskýRef, "STC": StředočeskýRef, "ULK": ÚsteckýRef, "VYS": VysočinaRef, "ZLK": ZlínskýRef }
    const krajNames = ["Hlavní město Praha", "Jihočeský kraj", "Jihomoravský kraj", "Karlovarský kraj", "Královehradecký kraj", "Liberecký kraj", "Moravskoslezský kraj", "Olomoucký kraj", "Pardubický kraj", "Plzeňský kraj", "Středočeský kraj", "Ústecký kraj", "Vysočina", "Zlínský kraj"]
    const krajShort = ['PHA', 'JHC', 'JHM', 'KVK', 'HKK', 'LBK', 'MSK', 'OLK', 'PAK', 'PLK', 'STC', 'ULK', 'VYS', 'ZLK']
    function handleEnter(e) {
        e.target.style.fill = '#16b2f0'
    }



    function handleLeave(e, freq) {
        const color = Color("#ffffff").mix(Color("#00cc99"), freq);  // Mix two colors
        e.target.style.fill = color.hex();  // Get the color in hexadecimal format
    }
    
    function gradient(freq) {
        const color = Color("#ffffff").mix(Color("#00cc99"), freq);
        return color.hex();  // Return the color in hexadecimal format
    }

    const tooltips = []
    for (let i = 0; i < krajNames.length; i++) {
        let shift = 0
        if (["Jihomoravský kraj", "Moravskoslezský kraj", "Olomoucký kraj", "Zlínský kraj"].includes(krajNames[i])) {
            shift = 250
        }

        tooltips.push(<Tooltip triggerRef={krajRefList[i]}>
            <rect x={30 - shift} y={20} width={300} height={110} rx={5} ry={5} fill="#4578ba" stroke='black' opacity='0.95' />
            <text x={50 - shift} y={50} fontSize={20} fill="white"> {krajNames[i]} </text>
            <text x={50 - shift} y={80} fontSize={20} fill="white"> {t('regional_freq')}: {
                resvar['freq_' + krajShort[i]] > 0
                    ? Number((resvar['freq_' + krajShort[i]] * 100).toPrecision(2))
                    : '-'
            } % </text>
            <text x={50 - shift} y={110} fontSize={20} fill="white"> {t('allele_count')}: {
                resvar['count_' + krajShort[i]] > 0
                    ? resvar['count_' + krajShort[i]]
                    : '-'
            } </text>
        </Tooltip>)
    }

    //const resvar_result = resvar
    //var resvar_result = Object.keys(resvar).map(x => [{ [x]: resvar[x] }][0])
    //var resvar_result = [{pos: resvar.pos, ref: resvar.ref}]
    let variant_full
    if (Object.keys(resvar).length > 0 & !decodeURI(window.location.pathname).includes('-')) {
        var resvar_result = cloneDeep([resvar])
        const vep_cols = 'Allele|Consequence|IMPACT|SYMBOL|Gene|Feature_type|Feature|BIOTYPE|EXON|INTRON|HGVSc|HGVSp|cDNA_position|CDS_position|Protein_position|Amino_acids|Codons|Existing_variation|DISTANCE|STRAND|FLAGS|VARIANT_CLASS|SYMBOL_SOURCE|HGNC_ID|CANONICAL|MANE_SELECT|MANE_PLUS_CLINICAL|TSL|APPRIS|CCDS|ENSP|SWISSPROT|TREMBL|UNIPARC|UNIPROT_ISOFORM|REFSEQ_MATCH|SOURCE|REFSEQ_OFFSET|GIVEN_REF|USED_REF|BAM_EDIT|GENE_PHENO|SIFT|PolyPhen|DOMAINS|miRNA|HGVS_OFFSET|AF|AFR_AF|AMR_AF|EAS_AF|EUR_AF|SAS_AF|gnomADe_AF|gnomADe_AFR_AF|gnomADe_AMR_AF|gnomADe_ASJ_AF|gnomADe_EAS_AF|gnomADe_FIN_AF|gnomADe_NFE_AF|gnomADe_OTH_AF|gnomADe_SAS_AF|gnomADg_AF|gnomADg_AFR_AF|gnomADg_AMI_AF|gnomADg_AMR_AF|gnomADg_ASJ_AF|gnomADg_EAS_AF|gnomADg_FIN_AF|gnomADg_MID_AF|gnomADg_NFE_AF|gnomADg_OTH_AF|gnomADg_SAS_AF|MAX_AF|MAX_AF_POPS|CLIN_SIG|SOMATIC|PHENO|PUBMED|MOTIF_NAME|MOTIF_POS|HIGH_INF_POS|MOTIF_SCORE_CHANGE|TRANSCRIPTION_FACTORS'.split("|")
        const kraje = ['PHA', 'JHC', 'JHM', 'KVK', 'HKK', 'LBK', 'MSK', 'OLK', 'PAK', 'PLK', 'STC', 'ULK', 'VYS', 'ZLK']

        resvar_result.forEach(x => x.pos = x.chrom + ':' + x.pos)
        resvar_result.forEach(x => x.freq_all = Number(x.freq_all.toPrecision(3)))
        resvar_result.forEach(x => x.rsID = x.annot.split('|')[vep_cols.indexOf('Existing_variation')]) //17
        resvar_result.forEach(x => x.gnomAD_AF = x.annot.split('|')[vep_cols.indexOf('gnomAD_AF')]) //52, combined 
        resvar_result.forEach(x => x.gnomAD_NFE_AF = x.annot.split('|')[vep_cols.indexOf('gnomAD_NFE_AF')]) //58, Non-Finnish European 
        resvar_result.forEach(x => x.Gene = x.annot.split('|')[vep_cols.indexOf('SYMBOL')]) //4, Non-Finnish European 
        resvar_result.forEach(x => x.Consequence = x.annot.split('|')[vep_cols.indexOf('Consequence')]) //1, Non-Finnish European 
        resvar_result.forEach(x => x.tot_alleles = x.total_samples * 2)
        resvar_result.forEach(x => x.sex_M_samples_freq = x.sex_M_samples_freq.toPrecision(2))


        //kraje.forEach(kraj => data.forEach(x => x[kraj] = JSON.parse(x.region)[kraj] != undefined ? JSON.parse(x.region)[kraj] : 0))
        kraje.forEach(kraj => resvar_result.forEach(x => x[kraj] = Number(x["freq_" + kraj].toPrecision(3))))



        const col_names = {
            pos: 'Position', ref: "Reference", alt: 'Alternate', rsID: "rs ID", freq_all: 'ACGT Czech Republic frequency', gnomAD_AF: "gnomAD global frequency",
            gnomAD_NFE_AF: "gnomAD Non-Finnish European frequency", Gene: 'Gene', Consequence: 'Consequence', sex_M_samples_freq: 'Male frequency', tot_alleles: 'Total alleles count', annot: "Annotation", het: 'Number of heterozygous genotypes', hom_plus: 'Number of homozygous genotypes with variant',
            PHA: 'Hlavní město Praha', JHC: 'Jihočeský kraj', JHM: 'Jihomoravský kraj', KVK: 'Karlovarský kraj', HKK: 'Královehradecký kraj', LBK: 'Liberecký kraj', MSK: 'Moravskoslezský kraj',
            OLK: 'Olomoucký kraj', PAK: 'Pardubický kraj', PLK: 'Plzeňský kraj', STC: 'Středočeský kraj', ULK: 'Ústecký kraj', VYS: 'Vysočina', ZLK: "Zlínský kraj"
        }
        var pre_cols = Object.keys(col_names)
        // var columns = pre_cols.map(x => [{
        //     Header: col_names[x],
        //     accessor: (d) => d[x],
        //     id: x
        // }][0])
        //Object.keys(variant.annot)[0].split('|') 

        var basic_info = (({ pos, ref, alt, rsID, freq_all, gnomAD_AF, gnomAD_NFE_AF, Consequence, tot_alleles, het, hom_plus }) => ({ pos, ref, alt, rsID, freq_all, gnomAD_AF, gnomAD_NFE_AF, Consequence, tot_alleles, het, hom_plus }))(resvar_result[0])
        var annotation = Object.fromEntries(vep_cols.map((x, idx) => [x, resvar_result[0].annot.split('|')[idx]]))
        var frequency_info = (({ PHA, JHC, JHM, KVK, HKK, LBK, MSK, OLK, PAK, PLK, STC, ULK, VYS, ZLK }) => ({ PHA, JHC, JHM, KVK, HKK, LBK, MSK, OLK, PAK, PLK, STC, ULK, VYS, ZLK }))(resvar_result[0])

        var basic_info_cols = Object.keys(basic_info).map(x => [{
            Header: col_names[x],
            accessor: (d) => d[x],
            id: x
        }][0])
        var annotation_cols = Object.keys(annotation).map(x => [{
            Header: x,
            accessor: (d) => d[x],
            id: x
        }][0])
        var frequency_info_cols = Object.keys(frequency_info).map(x => [{
            Header: col_names[x],
            accessor: (d) => d[x],
            id: x
        }][0])

        const style = {
            position: 'absolute',
            top: '720px',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            //width: 400,
            maxWidth: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }

        const styleReg = {
            position: 'absolute',
            top: '250px',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            //width: 400,
            maxWidth: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }

        var map_full = krajShort.map(x => {
            return (<path fill={gradient(resvar['freq_' + x])} onMouseEnter={e => handleEnter(e)} onMouseLeave={(e) => handleLeave(e, resvar['freq_' + x])} d={map ? map[x] : null} id={x} name={x} ref={krajRefObject[x]}>
            </path>)
        })

        variant_full = <AnimatedPage>
            <h2>{text}</h2>
            {Object.values(resvar).length > 0 ? <Container>
                {/* BASIC INFORMATION */}
                <Row style={{ placeContent: 'center' }}>
                    <h2>{t('basic_info')}</h2>
                </Row>
                <br />
                <Row style={{ placeContent: 'center' }}>
                    <table>
                        {basic_info_cols.map(x => <tr><td>{x.Header}</td><td></td>{basic_info[x.id]}</tr>)}
                    </table>
                </Row>
                <br />

                {/* ANNOTATION MODAL */}
                <Row style={{ placeContent: 'center' }}>
                    <Button variant="info" size="sm" className="fillAvailableBtns" onClick={handleOpen} style={{ width: '200px', margin: '20px' }}> {t('show_annot')}</Button>
                    <Modal
                        open={showAnnot}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ overflow: 'overlay' }}
                    >
                        <Box sx={style}>
                            <Row style={{ marginLeft: "auto" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Annotation
                                </Typography>
                                <Button variant="outlined" style={{ marginRight: '0px', marginLeft: "auto" }} onClick={handleClose}>x</Button>
                            </Row>
                            <br />
                            <table>
                                {annotation_cols.map(x => <tr><td>{x.Header}</td><td>{annotation[x.id]}</td></tr>)}
                            </table>
                        </Box>
                    </Modal>

                    <br />
                    {/* FREQUENCY MODAL */}
                    <Button variant="info" size="sm" className="fillAvailableBtns" onClick={handleOpenReg} style={{ width: '200px', margin: '20px' }}> {t('show_freq')}</Button>
                    <Modal
                        open={showReg}
                        onClose={handleCloseReg}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ overflow: 'overlay' }}
                    >
                        <Box sx={styleReg}>
                            <Row style={{ marginLeft: "auto" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t('region_freq')}
                                </Typography>
                                <Button variant="outlined" style={{ marginRight: '0px', marginLeft: "auto" }} onClick={handleCloseReg}>x</Button>
                            </Row>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {t('total_alleles')}: {resvar_result[0].total_samples * 2}
                            </Typography>
                            <br />
                            <table>
                                <th style={{ padding: '0 5px 0 5px' }}>{t('region')}</th>
                                <th style={{ padding: '0 5px 0 5px' }}>{t('freq')}</th>
                                <th style={{ padding: '0 5px 0 5px' }}>{t('allele_count')}</th>
                                <th style={{ padding: '0 5px 0 5px' }}>{t('total_alleles')}</th>
                                {frequency_info_cols.map(x => <tr>
                                    <td style={{ padding: '0 5px 0 5px' }}>{x.Header}</td>
                                    <td style={{ padding: '0 5px 0 5px', textAlign: 'center' }}>{frequency_info[x.id]}</td>
                                    <td style={{ padding: '0 5px 0 5px', textAlign: 'center' }}>{resvar_result[0]['count_' + x.id]}</td>
                                    <td style={{ padding: '0 5px 0 5px', textAlign: 'center' }}>{resvar_result[0]['samples_all_' + x.id] * 2}</td>
                                </tr>)}
                            </table>
                        </Box>
                    </Modal>
                </Row>
                {/* GRAPHS */}
                <Divider sx={{ mt: "1em", mb: "1em", width: "75%", ml: "auto", mr: "auto" }}></Divider>
                <Row style={{ placeContent: 'center' }}>
                    <h2>{t('freq_chart')}</h2>
                </Row>
                <br />
                <Row style={{ placeContent: 'center' }}>
                    <Graphs />
                </Row>
                <Divider sx={{ mt: "1em", mb: "1em", width: "75%", ml: "auto", mr: "auto" }}></Divider>
                {/* MAP */}
                <Row style={{ placeContent: 'center' }}>
                    <h2>{t('region_map')}</h2>
                </Row>
                <br />
                <Row style={{ placeContent: 'center', border: '1px' }}>
                    <svg baseProfile="tiny" fill="#99A5A6" height="570" strokeLinecap="round" strokeLinejoin="round" stroke="black" strokeWidth="0.1" version="1.2" viewBox="0 0 1000 570" width="1000" xmlns="http://www.w3.org/2000/svg">
                        {map_full}
                        <circle cx="233" cy="462.4" id="0">
                        </circle>
                        <circle cx="705.5" cy="454.6" id="1">
                        </circle>
                        <circle cx="489.5" cy="204.2" id="2">
                        </circle>

                        {tooltips}
                    </svg>
                </Row>
            </Container> : null}
        </AnimatedPage>

    } else {
        variant_full = 'No variants found for given URL'
    }

    return (
        <div>
            {variant_full}
        </div>
    )
}

export default Variant