import React, { useRef, useState, useEffect } from 'react'
import { TextField, Autocomplete, Grid, InputAdornment } from '@mui/material'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
// import PassQuery from './PassQuery'
import { useTranslation } from "react-i18next"
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { restableAction } from './Slices/restableSlice'
import { loadingAction } from './Slices/loadingSlice'
import { notFoundAction } from './Slices/notFoundSlice'
import { limitAction } from './Slices/limitSlice'
import { useNavigate } from 'react-router-dom'
import './CSS.css'
import { useAuth } from 'react-oidc-context'
import config from '../Config'
//import gene_list from '../Tables/gene_list_only'
import { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { Row } from 'react-bootstrap'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import 'reactjs-popup/dist/index.css';
import { errorAction } from "./Slices/errorSlice"


function SearchBar(props) {

    useEffect(() => {
        const fetchGeneList = async () => {
            try {
                // Dynamically import the gene_list module
                // const geneListModule = await import('../Tables/gene_list_only_112');
                const geneListModule = await import('../Tables/genes_112');

                // Access the default export from the module
                const genes = geneListModule.default;

                // Update the state with the fetched gene list
                setGeneList(genes);
            } catch (error) {
                console.error('Error fetching gene list:', error);
            }
        }
        fetchGeneList()
    })

    const [geneList, setGeneList] = useState([]);
    const gene_names = geneList.sort()
    const auth = useAuth();
    const api = axios.create({ baseURL: config.REACT_APP_BASE_API_URL, headers: { Authorization: `Bearer ${auth.user?.id_token}` } })
    const textRef = useRef()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [showForm, setShowForm] = useState(false)
    const [failQuery, setFailQuery] = useState(false)
    const [failList, setFailList] = useState('')

    const [sendQuery, setSendQuery] = useState(false)
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
    });


    // const [event, setEvent] = useState()
    // const [value, setValue] = useState()

    function toggleForm() {
        setShowForm(!showForm)
    }

    function toggleFailQuery() {
        setFailQuery(!failQuery)
    }

    // const makeGetRequest = async (query) => {
    //     try {
    //         const responses = await Promise.all(
    //             query.forEach( async (x) => {
    //                 const res = await api.get('/varList', { params: { string: x } }).then(res => {
    //                     if (res.data == 'error') {
    //                         return (res.data)
    //                     } else {
    //                         return (JSON.parse(res.data))
    //                     }
    //                 })
    //                 return res
    //             })
    //         )
    //         console.log(responses)
    //     } catch (error) {
    //         return ("Server Error: " + error.message)
    //     }
    // }

    function passMultiQuery() {
        toggleForm()
        const query = document.getElementById('multiQuery').value.trim().split('\n')
        dispatch(loadingAction(true))
        navigate('/')
        dispatch(notFoundAction(null))
        dispatch(errorAction(null))

        api.get('/varList', { params: { string: query } }).then(res => {
            var lim = res.data.lim
            var err = res.data.err

            var failed = res.data.res.map(x => x.length == 0 & Object.keys(x) != 'error').flatMap((bool, index) => bool ? index : [])
            if (failed.length > 0) {
                // setFailQuery(true)
                // setFailList(failed.map(i => res.config.params.string[i]))
                dispatch(notFoundAction(failed.map(i => res.config.params.string[i])))
            }
            if (lim.length > 0) {
                dispatch(limitAction(lim.map(i => query[i])))
            }
            if (err.length > 0) {
                dispatch(errorAction(err.map(x => x.error)))
            }
            if (res.data.res == 'error') {
                dispatch(restableAction(res.data.res))
                dispatch(loadingAction(false))
            } else if (res.data.res == '[]') {
                dispatch(restableAction(null))
                dispatch(loadingAction(false))
            } else {
                var tab = res.data.res.filter(x => Object.keys(x) != 'error')
                // var err = res.data.filter(x => Object.keys(x) == 'error').map(x => Object.values(x)[0])
                dispatch(restableAction([...new Map(tab.flat().map(obj => [obj.id, obj])).values()]))
                // dispatch(notFoundAction(err))
            }

        }).catch(function (error) {
            dispatch(restableAction("Server Error: " + error.message))
        }).then(() => {
            dispatch(loadingAction(false))
        })
        // const results = makeGetRequest(query)

    }

    function passQuery(event, value) {
        // test this and actually filter results + styling
        dispatch(notFoundAction(null))
        dispatch(limitAction(null))
        dispatch(errorAction(null))
        let query

        if (event.key == 'Enter' | event.type == 'click') {
            if (value == undefined) { // If clicked search btn
                query = textRef.current.value
            } else { // If enter
                query = value
            }
        } else { // If selected from dropdown
            query = value
        }

        if (query.trim() == '') {
            return null
        }
        //props.getQuery(query)

        query = query.trim()

        if (geneList.sort().includes(query)) {
            // const obj = geneList.find(obj => obj['gene'] === query)
            // const start = obj.start - 5000
            // const end = Number(obj.end) + 5000
            // var pseudo_query = 'chr' + obj.chr + ':' + start + '-' + end
            dispatch(loadingAction(true))
            navigate('/')
            api.get('/varList', { params: { string: query } }).then(res => {
                var lim = res.data.lim
                if (lim.length > 0) {
                    dispatch(limitAction([query]))
                }
                if (res.data.res == 'error') {
                    dispatch(restableAction(res.data.res))
                    dispatch(loadingAction(false))
                } else if (res.data.res == '[]') {
                    dispatch(restableAction(null))
                    dispatch(loadingAction(false))
                } else {
                    dispatch(restableAction(res.data.res[0]))
                }

            }).catch(function (error) {
                dispatch(restableAction("Server Error: " + error.message))
            })

        } else {
            if (query.includes('-')) {//Check if query contains numbers
                try {
                    var [pos1, pos2] = query.split(':')[1].split('-')
                    if (isNaN(Number(pos1)) | isNaN(Number(pos2))) {
                        dispatch(restableAction("Query Error: The query range does not contain only numbers"))
                        return
                    }
                } catch (e) {
                    dispatch(restableAction("Query Error: Wrong query format"))
                    return
                }

            }

            dispatch(loadingAction(true))
            navigate('/')
            api.get('/varList', { params: { string: query } }).then(res => {
                var lim = res.data.lim
                var err = res.data.err
                if (lim.length > 0) {
                    dispatch(limitAction([query]))
                }
                if (err.length > 0) {
                    dispatch(restableAction(err[0].error.msg))
                }
                if (res.data.res == 'error') {
                    dispatch(restableAction(res.data.res))
                    dispatch(loadingAction(false))
                } else if (Object.keys(res.data.res[0]) == 'error') {
                    dispatch(restableAction(res.data.res[0].error.msg))
                    dispatch(loadingAction(false))
                }else if (res.data.res == '[]') {
                    dispatch(restableAction(null))
                    dispatch(loadingAction(false))
                } else if (res.data.res == 'Query error: wrong query format') {
                    dispatch(restableAction('Query error: wrong query format'))
                    dispatch(loadingAction(false))
                } else if (res.data.res == 'Database error: cannot connect') {
                    dispatch(restableAction('Database error: cannot connect'))
                    dispatch(loadingAction(false))
                } else {
                    dispatch(restableAction(res.data.res[0]))
                }

            }).catch(function (error) {
                dispatch(restableAction("Server Error: " + error.message))
            })
        }

    }

    // function doQuery(event, value) {
    //     setSendQuery(true)
    //     setEvent(event)
    //     setValue(value)
    // }

    // function stopQuery() {
    //     setSendQuery(false)
    // }

    const style = {
        position: 'absolute',
        top: '700px',
        left: '50%',
        transform: 'translate(-50%, -80%)',
        width: 400,
        maxWidth: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        whiteSpace: 'break-spaces'
    }

    const multiSearchPane = <Grid container display="flex" justifyContent={"center"}>
        {!showForm ? <Button size="large" onClick={toggleForm} startIcon={<SearchIcon />}>{t('multi_search')}</Button> : <></>}
        {showForm ? <> <Modal
            open={toggleForm}
            onClose={toggleForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ overflow: 'overlay' }}
        >
            <Box sx={style} style={{ border: 'rgb(122, 193, 67) solid 2px', borderRadius: '15px', padding: '15px' }}>
                <Row >
                    <Button style={{ marginRight: '0px', marginLeft: "auto", color: 'black' }} onClick={toggleForm}>x</Button>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('enter_regions')}
                    </Typography>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <Button size="large" onClick={passMultiQuery} startIcon={<SearchIcon />} id='mutiSearch'>{t('search')}</Button>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <textarea id="multiQuery" style={{ width: '90%', height: '400px' }} />
                </Row>
            </Box>
        </Modal>  </> : <></>}
    </Grid>

    // const failedQuery = <Grid container display="flex" justifyContent={"center"}>
    //     {failQuery ? <> <Modal
    //         open={toggleFailQuery}
    //         onClose={toggleFailQuery}
    //         aria-labelledby="modal-modal-title"
    //         aria-describedby="modal-modal-description"
    //         style={{ overflow: 'overlay' }}
    //     >
    //         <Box sx={style} style={{ border: 'rgb(122, 193, 67) solid 2px', borderRadius: '15px', padding: '15px' }}>
    //             <Row >
    //                 <Button style={{ marginRight: '0px', marginLeft: "auto", color: 'black' }} onClick={toggleFailQuery}>x</Button>
    //             </Row>
    //             <Row style={{ justifyContent: 'center' }}>
    //                 <Typography id="modal-modal-title" variant="h6" component="h2">
    //                     Warning, these queries gave no results:
    //                     <br></br>
    //                     {failList.join(", ")}
    //                 </Typography>
    //             </Row>
    //             <br></br>
    //         </Box>
    //     </Modal>  </> : <></>}
    // </Grid>

    return (
        <>
            <Grid container display="flex" justifyContent={"center"}>
                <Grid item md={6} display="flex" alignItems={"center"}>
                    <Autocomplete
                        key={props.trigger}
                        style={{ margin: '5px' }}
                        // onChange={passQuery}
                        // onKeyPress={passQuery}
                        freeSolo
                        disablePortal
                        id="SearchQuery"
                        options={gene_names}//{[{ label: '', value: '' }]}
                        sx={{ width: "100%" }}
                        filterOptions={filterOptions}
                        renderInput={(params) => <TextField {...params} label={t('varsearch')} inputRef={textRef} InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button size="large" onClick={passQuery} startIcon={<SearchIcon />}>{t('search')}</Button>
                                </InputAdornment>
                            )
                        }} />}
                        // below makes sure that dropdown appears only if there is any text in search bar
                        open={open}
                        onChange={(event) => {
                            if (event.key == 'Enter') {
                                passQuery(event)
                            }
                        }}
                        onInputChange={(_, value) => {
                            // if (value.length === 0) {
                            if (value.length < 2) {
                                if (open) setOpen(false);
                            } else {
                                if (!open) setOpen(true);
                            }
                        }}
                        onClose={() => setOpen(false)}
                    />
                    {/* {sendQuery ?
                    <PassQuery event={event} value={value} text={textRef.current.value} stopQuery={stopQuery}/> :
                    null} */}
                </Grid>
            </Grid>
            {multiSearchPane}
            {/* {failedQuery} */}
        </>
    )
}
export default SearchBar