import { createSlice } from '@reduxjs/toolkit'

export const restableSlice = createSlice({
    name: 'restable',
    initialState: {
        value: null
    },
    reducers: {
        restableAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { restableAction } = restableSlice.actions

export default restableSlice.reducer